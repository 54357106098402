export default [
  // ---------------------------BDL
  // NF
  {
    path: '/notas/detalhada',
    name: 'app-nfdetalhado',
    component: () => import('@/views/app/bdl/notas/nfdetalhada'),
  },
  {
    path: '/notas/simples',
    name: 'app-nfsimples',
    component: () => import('@/views/app/bdl/notas/nfsimples'),
  },
  {
    path: '/eventos',
    name: 'app-eventos',
    component: () => import('@/views/app/bdl/eventos/index'),
  },
  // MARKETING
  {
    path: '/marketing',
    name: 'app-marketing',
    component: () => import('@/views/app/bdl/marketing/index'),
  },
  // Portaria
  {
    path: '/portaria',
    name: 'app-portaria',
    component: () => import('@/views/app/bdl/portaria/index'),
  },
  // Comandas
  {
    path: '/comandas',
    name: 'app-comandas',
    component: () => import('@/views/app/bdl/comandas/index'),
  },
  {
    path: '/comandas/add',
    name: 'app-comandas-add',
    component: () => import('@/views/app/bdl/comandas/addComanda'),
  },
  {
    path: '/notas/add',
    name: 'app-comandas-app',
    component: () => import('@/views/app/bdl/comandas/adicionar'),
  },
]
